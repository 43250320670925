import * as CookieConsent from "vanilla-cookieconsent";

const revision = 1725444014 // Timestamp

const cc = CookieConsent

window.cc = cc

const setApiCookieConsent = (cookie) => {

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    fetch('/api/cookie-consent', {
        method: "POST",
        body: JSON.stringify(cookie),
        headers,
    })
}

cc.run({
    mode: 'opt-in',
    revision,
    guiOptions: {
        consentModal: {
            layout: 'box wide',
            position: 'middle center',
        }
    },

    onFirstConsent: ({ cookie }) => {
        setApiCookieConsent(cookie)
    },

    onChange: ({ cookie }) => {
        setApiCookieConsent(cookie)
    },

    disablePageInteraction: true,

    categories: {
        necessary: {
            enabled: true,
            readOnly: true,
        },
        analytics: {
            enabled: false,
            readOnly: false,
        }
    },
    language: {
        default: 'de',
        autoDetect: 'document',
        translations: {
            da: '/api/translations/c-consent/da.json',
            de: '/api/translations/c-consent/de.json',
            en: '/api/translations/c-consent/en.json',
            es: '/api/translations/c-consent/es.json',
            fr: '/api/translations/c-consent/fr.json',
            it: '/api/translations/c-consent/it.json',
            nl: '/api/translations/c-consent/nl.json',
            pt: '/api/translations/c-consent/pt.json',
            fi: '/api/translations/c-consent/fi.json',
            sv: '/api/translations/c-consent/sv.json',
            ru: '/api/translations/c-consent/ru.json',
            zh: '/api/translations/c-consent/zh.json',
        }
    }
})